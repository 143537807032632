.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-message-notice
{
  display: none;
}
.hidden{
  display: none;
}
.table-container {
  overflow-x: auto;
}

.scrollable-content {
  min-width: 1000px; /* Adjust as needed */
}